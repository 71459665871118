import { WineVintagesResponse } from '~/networking/api/strapi/responses';
import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getCollections = ({ getHeaders, getRequestUrl }: StrapiRequestOptions) => {
    return async function (params?: string): Promise<Response<WineVintagesResponse>> {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/collections${params ? `?collectionId=${params}` : ''}`);

        return httpRequest.get(httpRequestUrl, { headers, keepalive: true });
    };
};
