import { WineVintagesResponse } from '~/networking/api/strapi/responses';
import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { buildParameters } from '~/networking/utils/buildParameters';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getWineVintages =
    ({ getHeaders, getRequestUrl }: StrapiRequestOptions) =>
    async (filterOptions: {
        start: number;
        limit?: number;
        active?: boolean;
    }): Promise<Response<WineVintagesResponse>> => {
        const { start, limit, active = true } = filterOptions;
        const headers = getHeaders(true);
        const params = { start, limit, active };
        const httpRequestUrl = getRequestUrl(`/vintages${buildParameters({ ...params })})`);

        return httpRequest.get(httpRequestUrl, { headers });
    };
