import {
    cancelTradingPortfolioOrderById,
    createTradingPortfolio,
    createTradingPortfolioOrder,
    getCollectionById,
    getMarketValue,
    getTradingPortfolio,
    getTradingPortfolioBottles,
    getTradingPortfolioCharges,
    getTradingPortfolioOrderById,
    getTradingPortfolioOrders,
    getTradingPortfolioTransactions,
    getTransactions
} from '~/networking/api/trading/requests';
import { cancelOrder } from '~/networking/api/trading/requests/orders/cancelOrder';
import { getOrder } from '~/networking/api/trading/requests/orders/getOrder';
import { getOrders } from '~/networking/api/trading/requests/orders/getOrders';

import { tradingInitializationConfig } from './tradingInitializationConfig';
import { MakeTradingRequestOptions } from '../../VinovestNetworkingClient';

export interface TradingApis {
    /**
     * @details Create a trading portfolio for a user.
     * @remarks *POST /v1/portfolio*
     */
    createTradingPortfolio: ReturnType<typeof createTradingPortfolio>;
    /**
     * @details Retrieve a collection by it's Id.
     *
     * @param collectionId - Id of the wine collection
     *
     * @remarks *GET /v1/collection/{collectionId}*
     */
    getCollectionById: ReturnType<typeof getCollectionById>;
    /**
     * @details Cancel an order for a user's trading portfolio
     *
     * @param  postBody - order details to create trading portfolio order
     * @remarks *POST /v1/portfolio/{portfolioID}/orders*
     */
    createTradingPortfolioOrder: ReturnType<typeof createTradingPortfolioOrder>;
    /**
     * @details Cancel an order for a user's trading portfolio
     *
     * @param orderId - the orderId to cancel
     *
     * @remarks *DELETE /v1/portfolio/{portfolioId}/order/{orderId}*
     */
    cancelTradingPortfolioOrderById: ReturnType<typeof cancelTradingPortfolioOrderById>;
    /**
     * @details Retrieve a user's trading portfolio
     * @remarks *GET /v1/portfolio/{portfolioID}*
     */
    getTradingPortfolio: ReturnType<typeof getTradingPortfolio>;
    /**
     * @details Get bottles for a user's trading portfolio
     *
     * @remarks *GET /v1/portfolio/{portfolioId}/bottles*
     */
    getTradingPortfolioBottles: ReturnType<typeof getTradingPortfolioBottles>;
    /**
     * @details retrieve the charges of a users trading portfolio
     * @remarks *GET /v1/portfolio/{portfolioID}*
     */
    getTradingPortfolioCharges: ReturnType<typeof getTradingPortfolioCharges>;
    /**
     * @details Retrieve the trading portfolio that is associated with a particular order.
     *
     * @param orderId - orderId of a bid or ask
     * @remarks *GET /v1/portfolio/{portfolioID}/order/{orderID}*
     */
    getTradingPortfolioOrderById: ReturnType<typeof getTradingPortfolioOrderById>;
    /**
     * @details Get transactions for a user's trading portfolio
     * @remarks *GET /v1/portfolio/{portfolioID}/transactions*
     */
    getTradingPortfolioTransactions: ReturnType<typeof getTradingPortfolioTransactions>;
    /**
     * @details Retrieve the orders of a the current users trading portfolio
     *
     * @remarks *GET /v1/portfolio/{portfolioID}/order*
     */
    getTradingPortfolioOrders: ReturnType<typeof getTradingPortfolioOrders>;
    /**
     * @details Lookup best bid, ask, and FMV for given lwin16s
     *
     * @remarks *GET /v1/markets?lwin16s={lwin16}
     */
    getMarketValue: ReturnType<typeof getMarketValue>;
    getOrders: ReturnType<typeof getOrders>;
    getTransactions: ReturnType<typeof getTransactions>;
    cancelOrder: ReturnType<typeof cancelOrder>;
    getOrder: ReturnType<typeof getOrder>;
}
export const makeTradingApis = (tradingConfig: Omit<MakeTradingRequestOptions, 'getHeaders'>): TradingApis => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const config = tradingInitializationConfig(tradingConfig as any);
    return {
        createTradingPortfolio: createTradingPortfolio(config),
        getCollectionById: getCollectionById(config),
        createTradingPortfolioOrder: createTradingPortfolioOrder(config),
        cancelTradingPortfolioOrderById: cancelTradingPortfolioOrderById(config),
        getTradingPortfolio: getTradingPortfolio(config),
        getTradingPortfolioBottles: getTradingPortfolioBottles(config),
        getTradingPortfolioCharges: getTradingPortfolioCharges(config),
        getTradingPortfolioOrderById: getTradingPortfolioOrderById(config),
        getTradingPortfolioTransactions: getTradingPortfolioTransactions(config),
        getTradingPortfolioOrders: getTradingPortfolioOrders(config),
        getMarketValue: getMarketValue(config),
        getOrders: getOrders(config),
        getTransactions: getTransactions(config),
        cancelOrder: cancelOrder(config),
        getOrder: getOrder(config)
    };
};
