export enum RequestMethods {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Patch = 'PATCH',
    Delete = 'DELETE'
}

export type Response<T> = globalThis.Response & { data?: T };
export interface HttpRequestInit extends Omit<RequestInit, 'body'> {
    body?: unknown;
}

const httpRequestor =
    (method: string) =>
    async <T>(url: string, httpRequestInit?: HttpRequestInit): Promise<Response<T>> => {
        const body: BodyInit | null =
            httpRequestInit?.body && typeof httpRequestInit.body !== 'string' && method !== RequestMethods.Get
                ? JSON.stringify(httpRequestInit.body)
                : (httpRequestInit?.body as string) || null;

        if (typeof window === 'undefined') {
            console.info(`Requesting ${method} ${url}`);
        }

        const response = await fetch(
            url,
            httpRequestInit
                ? {
                      method,
                      ...httpRequestInit,
                      body
                  }
                : undefined
        );

        const { status, ok, statusText } = response;
        const result = {
            ...response,
            status,
            ok,
            statusText
        };
        if (status === 204) {
            return result;
        }
        if (response.headers.get('content-type') === 'text/csv') {
            const data = (await response.text()) as unknown as T | undefined;
            return { data, ...result };
        }
        if (response.headers.get('content-length') === '0') {
            return { data: undefined, ...result };
        }
        try {
            const data = (await response.json()) as T;
            return { data, ...result };
        } catch (error: unknown) {
            console.error('Request %O:%O failed to decode due to %O on %O', method, url, response.statusText, error);
            throw error;
        }
    };

export const httpRequest = {
    get: httpRequestor(RequestMethods.Get),
    post: httpRequestor(RequestMethods.Post),
    put: httpRequestor(RequestMethods.Put),
    patch: httpRequestor(RequestMethods.Patch),
    delete: httpRequestor(RequestMethods.Delete)
};
