import { StrapiWineProducersResponse } from '~/networking/api/strapi/responses/StrapiWineProducers';
import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { StrapiParameters, strapiQueryMapping } from '~/networking/models/StrapiParameters';
import { buildParameters } from '~/networking/utils/buildParameters';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getProducers =
    ({ getHeaders, getRequestUrl }: StrapiRequestOptions) =>
    async (params: Partial<StrapiParameters>): Promise<Response<StrapiWineProducersResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/producers${params ? buildParameters(params, strapiQueryMapping) : ''}`);

        return httpRequest.get(httpRequestUrl, { headers, keepalive: true });
    };
