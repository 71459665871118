import { QueryClient, useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import {
    VinovestNetworkHelperAuthenticationParams,
    VinovestNetworkingHelper
} from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';
import { PortfolioCharges } from '~/networking';

export const PendingTransactionsQuery: QueryDefinition<PortfolioCharges> = {
    load: async (portfolioId: string, authenticationParams: VinovestNetworkHelperAuthenticationParams) => {
        try {
            const { managed } = await VinovestNetworkingHelper.getClient(authenticationParams);
            const result = await managed.getPortfolioCharges(portfolioId);
            if (result.ok && result.data) {
                return result.data ?? null;
            }

            return null;
        } catch (error) {
            SentryHelper.captureException(`Error loading managed pending transactions query: ${error}`);
            return null;
        }
    },
    queryKey: (userId: string, portfolioId: string) => [queries.getPendingTransfers, userId, portfolioId],
    prefetch: async (queryClient: QueryClient, authenticationParams: VinovestNetworkHelperAuthenticationParams) => {
        const { userId } = authenticationParams;
        const queryKey = PendingTransactionsQuery.queryKey(userId || '');
        await queryClient.prefetchQuery(queryKey, async () => PendingTransactionsQuery.load(authenticationParams));
    }
};

export const usePendingPortfolioTransactions = (portfolioId?: string) => {
    const { isAuthenticated, userId, jwt } = useAuthentication(false);

    return useQuery(
        PendingTransactionsQuery.queryKey(userId as string, portfolioId),
        async () => PendingTransactionsQuery.load(portfolioId, { isAuthenticated, userId, accessToken: jwt }),
        {
            enabled: isAuthenticated && !!portfolioId,
            cacheTime: 100
        }
    );
};
