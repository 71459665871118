import { ApiTypes } from '~/networking';

interface GetHostnameOptions {
    type: ApiTypes;
}

let serverHostnames: Record<ApiTypes, string> = {} as Record<ApiTypes, string>;

// configuration at runtime comes from the server, this is set during the initial app render for each request
export const setServerHostnames = (hostnames: Record<ApiTypes, string>) => {
    serverHostnames = hostnames;
};

// eslint-disable-next-line consistent-return
export const getHostname = (type: ApiTypes): string => {
    if (typeof window === 'undefined') {
        switch (type) {
            case ApiTypes.CMS:
                return (
                    process.env.CMS_API_URL || process.env.NEXT_PUBLIC_CMS_API_URL || 'https://cms.staging.vinovest.co'
                );
            case ApiTypes.Strapi:
                return process.env.NEXT_PUBLIC_STRAPI_API_URL;
            case ApiTypes.Trading:
                return process.env.BASE_TRADING_ENDPOINT;
            case ApiTypes.Managed:
                return process.env.BASE_ENDPOINT;
            default: {
                // noinspection UnnecessaryLocalVariableJS
                const never: never = type;
                return never;
            }
        }
    }

    switch (type) {
        case ApiTypes.CMS: {
            return serverHostnames[type] || process.env.NEXT_PUBLIC_CMS_API_URL || 'https://cms.staging.vinovest.co';
        }
        case ApiTypes.Strapi: {
            return serverHostnames[type] || process.env.NEXT_PUBLIC_STRAPI_API_URL;
        }
        case ApiTypes.Trading: {
            return serverHostnames[type] || process.env.NEXT_PUBLIC_BASE_TRADING_ENDPOINT;
        }
        case ApiTypes.Managed: {
            return serverHostnames[type] || process.env.NEXT_PUBLIC_BASE_ENDPOINT;
        }
        default: {
            // noinspection UnnecessaryLocalVariableJS
            const never: never = type;
            return never;
        }
    }
};

export const getHostPathGenerator = ({ type }: GetHostnameOptions): ((pathName: string) => string) => {
    return (pathName: string) => {
        return `${getHostname(type)}${pathName}`.replace(/\/$/, '');
    };
};
