import { WineDescriptionResponse } from '~/networking/api/strapi/responses';
import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getLwin7FromSlug =
    ({ getHeaders, getRequestUrl }: StrapiRequestOptions) =>
    async (slug: string): Promise<Response<WineDescriptionResponse>> => {
        const headers = await getHeaders(true);
        const getLwin7FromSlugApiCommandEndpoint = getRequestUrl(`/lwin-7-s?slug=${slug}`);

        return httpRequest.get(getLwin7FromSlugApiCommandEndpoint, { headers, keepalive: true });
    };
