import Cookies from 'js-cookie';
import { Router } from 'next/router';
import { v4 as uuid } from 'uuid';

import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import { VinovestNetworkingHelper } from '~/helpers/VinovestNetworkingHelper';
import { OktaAuthClient } from '~/utils/oktaAuthClient';

const cookieId = 'impactClickID';

export const ImpactHelper = {
    setImpactClickIDCookie(query: Router['query']) {
        const impactReferralParam = query.irclickid;
        const clickId: string | undefined = Array.isArray(impactReferralParam)
            ? impactReferralParam[0]
            : impactReferralParam;
        if (clickId) {
            Cookies.set(cookieId, clickId);
        }
    },
    async postImpactUserSignup(userId: string) {
        const impactClickId = Cookies.get(cookieId);
        if (!impactClickId) {
            return;
        }

        try {
            const { managed } = VinovestNetworkingHelper.getClient();
            await managed.postImpactSignup({
                orderID: userId,
                clickID: impactClickId
            });
            Cookies.remove(cookieId);
        } catch (error) {
            SentryHelper.captureException(error, {
                extra: {
                    impactClickId,
                    impactOrderID: userId
                }
            });
            /** no-op we don't want to crash here */
        }
    },
    async postImpactBookACallData(eventDate: string | undefined = undefined) {
        const impactOrderID = `OID-${uuid()}`;
        try {
            const okta = await OktaAuthClient.getInstance();
            if (await okta.isAuthenticated()) {
                const { managed } = VinovestNetworkingHelper.getClient();
                await managed.postImpactBookACall({
                    orderID: impactOrderID,
                    eventDate: eventDate || new Date().toISOString()
                });
            }
        } catch (error) {
            SentryHelper.captureException(error, {
                extra: {
                    eventDate,
                    impactOrderID
                }
            });
        }
    }
};
