import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

import { StrapiRegionsResponse } from '../responses/StrapiRegion';

export const getRegionsByNames =
    ({ getHeaders, getRequestUrl }: StrapiRequestOptions) =>
    async (names: string[] | string): Promise<Response<StrapiRegionsResponse>> => {
        const queryParameters =
            typeof names === 'string' ? `name=${names}` : names.map((name) => `name=${name}`).join('&');
        const headers = getHeaders(true);
        const getRegionsByNamesEndpoint = getRequestUrl(`/regions?${queryParameters}`);

        return httpRequest.get(getRegionsByNamesEndpoint, { headers, keepalive: true });
    };
