/**
 * This file is autogenerated, do not modify. It will be created and
 * overwritten again every build
 */

export * from './GetActiveVintages';

export * from './StrapiCollectionsDescription';

export * from './StrapiWineCritics';

export * from './StrapiWineDescription';

export * from './StrapiWineProducers';

export * from './StrapiWineVintage';
