import {
    getCollections,
    getCountries,
    getLwin7Descriptions,
    getLwin7FromSlug,
    getLwin7sFromRegion,
    getLwin11Vintage,
    getRegionsFromCountry,
    getWineVintages
} from '~/networking/api/strapi/requests';
import { getRegionsByNames } from '~/networking/api/strapi/requests/getRegionsByNames';

import { strapiIntializationConfiguration } from './strapiIntializationConfiguration';

export interface StrapiApis {
    getCollections: ReturnType<typeof getCollections>;
    /**
     * @details Retrieve countries of wine producers
     *
     * @param {Partial<StrapiParameters>} params Query parameters to filter
     * @remarks *GET /countries*
     */
    getCountries: ReturnType<typeof getCountries>;
    /**
     * @details Get the description of a winery by their lwin 7
     *
     * @param {string[] | string} lwin7Ids - Id or Ids of the lwin7(s) to retrieve descriptions for
     *  @remarks *GET /lwin-7-s?lwin7=string[]*
     */
    getLwin7FromSlug: ReturnType<typeof getLwin7FromSlug>;
    /**
     * @details Get a winery lwin7 from the specified slug
     *
     * @param {string} slug - url slug to identify the lwin7 number it is associated with
     * f@remarks *GET /lwin-7-s?slug=[slug]*
     */
    getLwin7Descriptions: ReturnType<typeof getLwin7Descriptions>;
    /**
     * @details Get the wineries in a specific region
     *
     * @param {string} region - the wine region
     *  @remarks *GET /lwin-7-s?lwin7=string[]*
     */
    getLwin7sFromRegion: ReturnType<typeof getLwin7sFromRegion>;
    /**
     * @details Retrieve vintage information associated with lwin11(s)
     *
     * @param {string[]} lwin11Ids lwin11 ids to search for their vintage information for
     * @remarks *GET /vintages?lwin11ids[]*
     */
    getLwin11Vintage: ReturnType<typeof getLwin11Vintage>;
    /**
     * @details Get regions associated with a country
     *
     * @param {string} country country to get regions of
     * @remarks *GET /countries*
     */
    getRegionsFromCountry: ReturnType<typeof getRegionsFromCountry>;
    /**
     * @details Get wine vintages with optional filtering
     * @param filterOptions - options for filtering
     * @remarks *GET /vintages?{params}*
     */
    getWineVintages: ReturnType<typeof getWineVintages>;
    /**
     * @details Get all the regions for the passed in region name
     * @param name - name of region
     * @remarks *GET /regions?name=${name}*
     */
    getRegionsByNames: ReturnType<typeof getRegionsByNames>;
}
export const makeStrapiApis = (strapiConfig: { strapiToken: string }): StrapiApis => {
    const config = strapiIntializationConfiguration(strapiConfig);

    return {
        getCollections: getCollections(config),
        getCountries: getCountries(config),
        getLwin7FromSlug: getLwin7FromSlug(config),
        getLwin7Descriptions: getLwin7Descriptions(config),
        getLwin7sFromRegion: getLwin7sFromRegion(config),
        getLwin11Vintage: getLwin11Vintage(config),
        getRegionsFromCountry: getRegionsFromCountry(config),
        getWineVintages: getWineVintages(config),
        getRegionsByNames: getRegionsByNames(config)
    };
};
