import { getHostPathGenerator } from '~/networking';

import { headerFactory } from '../../headerFactory';
import { ApiGenerationOptions, ApiTypes } from '../../VinovestNetworkingClient';

export const managedInititializationConfig = ({
    getAccessToken,
    getPortfolioId,
    getCurrencyCode,
    getUserId
}: Exclude<ApiGenerationOptions, 'strapiToken'>) => {
    return {
        getPortfolioId,
        getUserId,
        getCurrencyCode,
        getRequestUrl: getHostPathGenerator({ type: ApiTypes.Managed }),

        getHeaders: (secure?: boolean, extraHeaders?: { [key: string]: string } | Headers) =>
            headerFactory({ secure, getAccessToken, getCurrencyCode, type: ApiTypes.Managed, headers: extraHeaders })
    };
};
