import { useMutation } from 'react-query';

import { VinovestNetworkingHelper } from '~/helpers/VinovestNetworkingHelper';
import { ReferralCookieHelper, ReferralStatus } from '~/tracking/referral/ReferralCookieHelper';

export const useLinkFreeFeesReferral = () => {
    return useMutation(['useLinkReferral'], async (userId: string) => {
        const { managed } = await VinovestNetworkingHelper.getClient();
        const referralCode = ReferralCookieHelper.getReferralCookie();

        if (!referralCode) {
            return ReferralStatus.NotLinked;
        }

        if (!userId) {
            return ReferralStatus.NotLinked;
        }

        const referralStatus = await managed.getUserReferralProfileStatus();

        if (!referralStatus.ok || !referralStatus.data?.shareUrl) {
            return ReferralStatus.NotLinked;
        }
        const referralUrl = new URL(referralStatus.data.shareUrl);
        const userReferralId = referralUrl.searchParams.get('grsf');

        if (referralCode) {
            if (referralCode === userReferralId) {
                ReferralCookieHelper.removeReferralCookie();
            } else {
                const response = await managed.putLinkReferralInviteCode(referralCode);
                if (response.ok) {
                    ReferralCookieHelper.removeReferralCookie();
                }

                return response.ok ? ReferralStatus.Linked : ReferralStatus.Failed;
            }
        }

        return ReferralStatus.NotLinked;
    });
};
