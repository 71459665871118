import {
    ApiGenerationOptions,
    getUserPreferences,
    managedInititializationConfig,
    updateUserPreferences
} from '~/networking';
import { postImpactBookACall } from '~/networking/api';
import { getAccountDocumentLink } from '~/networking/api/managed/requests/documents/getAccountDocumentLink';
import { getAccountStatementMonths } from '~/networking/api/managed/requests/documents/getAccountStatementMonths';
import { getAccountStatements } from '~/networking/api/managed/requests/documents/getAccountStatements';
import { postImpactSignup } from '~/networking/api/managed/requests/impact/postImpactSignup';
import {
    cancelOfferListing,
    createOfferListing,
    getOfferListings,
    getPendingOfferListings,
    patchBulkFmvpeg,
    patchFmvpeg,
    postBulkAutolistWines,
    putOfferListing,
    reserveUpsellOfferGroup
} from '~/networking/api/managed/requests/offers';
import { cancelReservedOffers } from '~/networking/api/managed/requests/offers/cancelReservedOffers';
import { getReservationsInGroup } from '~/networking/api/managed/requests/offers/getReservationsInGroup';
import { getUpsellOfferGroup } from '~/networking/api/managed/requests/offers/getUpsellOfferGroup';
import { purchaseReservedOffers } from '~/networking/api/managed/requests/offers/purchaseReservedOffers';
import { startReservedEscrow } from '~/networking/api/managed/requests/offers/startReservedEscrow';
import {
    createAchAccount,
    createBitpayInvoice,
    createPaymentSubscription,
    createPlaidPaymentIntent,
    createStripePaymentIntent,
    createStripeSecretKey,
    createStripeSubscriptionManagementUrl,
    getPlaidLinkToken,
    getUniqueWireInformation,
    getUserHasSubscriptions,
    getUserPaymentMethods
} from '~/networking/api/managed/requests/payments';
import {
    cancelBulkLiquidateWines,
    getActiveBids,
    getPortfolioAssetsAsCsv,
    getPortfolioCharges,
    getPortfolioFees,
    getPortfolioWine,
    getUserPortfolio,
    getUserWines,
    postBulkLiquidateWines
} from '~/networking/api/managed/requests/portfolio';
import { putAllocationClassification } from '~/networking/api/managed/requests/portfolio/putAllocationClassification';
import { putUpdateInvestmentPlan } from '~/networking/api/managed/requests/portfolio/putUpdateInvestmentPlan';
import { putUserProfile } from '~/networking/api/managed/requests/portfolio/putUserProfile';
import {
    createNewUser,
    createWhiskeyvestPortfolio,
    getBankWireKeyReference,
    getBankWireUsInformation,
    getUserAutoPayStatus,
    getUserWithdrawalEligibility,
    getUserLinkedBanks,
    postUserLinkedBanks,
    postUserWithdrawal,
    getUserCashAwardReferralStatus,
    getUserInformation,
    getUserPortfolios,
    getUserProfile,
    getUserReferralProfileStatus,
    postSendInviteEmails,
    putLinkCashAwardReferral,
    putLinkReferralInviteCode,
    putUserAutoPayCancel,
    putUserAutoPayUpdate,
    putUserInformation,
    putUserPassword
} from '~/networking/api/managed/requests/users';

export interface ManagedApis {
    /**
     * Will link a user to their referrer for Cash Reward Campaign
     * @details Retrieve pending transfers for a particular user
     * @remarks *GET /v2/user/{userID}/referral/status*
     * */
    getUserCashAwardReferralStatus: ReturnType<typeof getUserCashAwardReferralStatus>;
    /**
     * Will link a user to their referrer for Cash Reward Campaign
     * @details Retrieve pending transfers for a particular user
     * @remarks *PUT /v2/user/{userID}/referral*
     * @see {@link https://github.com/Vinovest/backend/blob/089b5b18647dcd10b6f43b19b1fa9542392e7301/pkg/routes/user/user.go#L332-L342 Endpoint Code}
     * */
    putLinkCashAwardReferral: ReturnType<typeof putLinkCashAwardReferral>;
    /**
     *  @details Create a payment intent for a credit card deposit
     * @param {number} amountCents Amount in cents to create the payment intent for.
     * @remarks *POST /v1/payments/portfolio/{portfolioId}*
     *  */

    createStripeSecretKey: ReturnType<typeof createStripeSecretKey>;
    /**
     * @details Create Stripe secret key to create credit card payment intent.
     * @remarks *POST /v1/payment/{userID}/portfolio/{portfolioId}/intent/card*
     *  */
    createStripePaymentIntent: ReturnType<typeof createStripePaymentIntent>;
    /**
     * @details Create Stripe credit card payment intent.
     * @remarks *POST /v1/payment/{userID}/portfolio/{portfolioId}/intent*
     *  */
    createPaymentSubscription: ReturnType<typeof createPaymentSubscription>;
    /**
     * @details Create recurring payment or single charge for a user.
     * @param {number} amount Amount to transfer
     * @param {string} accountID user account ID
     * @param {string} portfolioID user portfolio ID
     * @param {string} interval Deposit frequency
     * @param {string} startDate Start and date of recurring charge
     * @remarks *POST /v1/payment/{userID}/portfolio/{portfolioId}/charge*
     *  */
    createPlaidPaymentIntent: ReturnType<typeof createPlaidPaymentIntent>;
    /**
     * @details Create Stripe credit card payment intent.
     * @remarks *POST /v1/payment/{userID}/portfolio/{portfolioId}/stripe/ach*
     * @param {string} publicToken plaid token.
     * @param {string} accountID plaid account ID *  */
    createBitpayInvoice: ReturnType<typeof createBitpayInvoice>;
    /**
     * @details Create Stripe credit card payment intent.
     * @remarks *POST /v1/account/{userID}/bitpay/invoice*
     * @param {number} amount Amount to transfer
     * @param {string} destination Managed or trading portfolio
     * @param {string} portfolioId When to start the reocurring depost
     * @param {string} redirect where user should be sent when payment is completed.
     *  */
    createNewUser: ReturnType<typeof createNewUser>;
    /**
     * @details Create an ach account for the current user.
     * @param {string} publicAccountToken public token for accessing accounts api
     * @remarks *POST /v1/payment/{userID}}/portfolio/{portfolioId}/stripe/ach*
     */
    createAchAccount: ReturnType<typeof createAchAccount>;
    /**
     * @details used to retrieve a unique plaid link token for the logged in user
     * @remarks *GET /v1/payment/{userID}/portfolio/{portfolioID}/plaid/link*
     */
    getPlaidLinkToken: ReturnType<typeof getPlaidLinkToken>;
    /**
     * @details Creates and returns a link to display Stripe's subscription management UI
     * @remarks *GET /v1/payment/{userID}/portfolio/{portfolioID}/subscription*
     * */
    getUserHasSubscriptions: ReturnType<typeof getUserHasSubscriptions>;
    /**
     * @details Creates gets a users payment methods
     * @remarks *GET /v1/payment/portfolio/{portfolioId}/{userID}*
     *  */
    getUserPaymentMethods: ReturnType<typeof getUserPaymentMethods>;
    /**
     * @details Retrieve active bids on wine for the given portfolio related to a user's portfolio
     * @remarks *GET /v1/portfolio/{portfolioId}*
     */
    getActiveBids: ReturnType<typeof getActiveBids>;
    /**
     *  @details Retrieve completed fees related to a user's portfolio
     * @param {string} pageToken Token identifying where to start when paginating
     * @param {string} portfolioId portfolio to get fees from
     *
     * @remarks *GET /v2/portfolio/{portfolioId)}/fees?pageToken={pageToken}*
     */
    getPortfolioFees: ReturnType<typeof getPortfolioFees>;
    /**
     * @details Retrieve financial details about a wine in a user's portfolio
     * @param {string} lwin18 The lwin18 of the wine to retrieve from a portfolio
     *
     * @remarks *GET /v1/portfolio/{portfolioId}/wine/{lwin18}*
     */
    getPortfolioAssetsAsCsv: ReturnType<typeof getPortfolioAssetsAsCsv>;
    /**
     * @details Retrieve all assets in portfolio, formatted for export to CSV.
     * @param {string} portfolioId the portoflio ID you want to query
     * @remarks *GET /v1/portfolio/{portfolioID}/wines/csv*
     */
    getPortfolioWine: ReturnType<typeof getPortfolioWine>;
    /**
     * @details Retrieve information about a user's portfolio
     * @param {PortfolioPerformanceRanges} range one of:
     * OneWeek = '1W',
     * OneMonth = '1M',
     * SixMonths = '6M',
     * OneYear = '1Y',
     * All = 'all'
     *
     * @remarks *GET /v1/portfolio/{userId}?range={range}*
     */
    getUserPortfolio: ReturnType<typeof getUserPortfolio>;
    /**
     * @details Retrieve wines within a user's portfolio
     * @param {number} pageSize number of entries to retrieve (max 10) @default 10
     * @param {string} pageToken page token for pagination @default undefined
     * @param {string} portfolioId the portoflio ID you want to query @default undefined
     * @remarks *GET /v1/portfolio/{portfolioId}/wines{pageTokenParameter}*
     * */
    getUserWines: ReturnType<typeof getUserWines>;
    /**
     * @details Retrieve wines that can be listed by the portfolio
     * @remarks *GET /v1/offer/portfolio/{portfolioID}/pending/listings*
     * @param {string} portfolioID The portfolioID of the portfolio getting available listings for
     * id: string,
     * listedAt: string,
     * lwin18": string",
     * offerPrice: {
     *    amount: 0,
     *    currency: "string"
     * }
     * */
    getPendingOfferListings: ReturnType<typeof getPendingOfferListings>;
    /**
     * @details Retrieve wines that have been listed by the portfolio
     * @remarks *GET /v1/offer/portfolio/{portfolioID}*
     * @param {string} portfolioID The portfolioID of the portfolio getting available listings for
     * id: string;
     * listedAt: string;
     * lwin18: string;
     * offerPrice: {
     *    amount: 0;
     *    currency: string;
     * };
     * */
    getOfferListings: ReturnType<typeof getOfferListings>;
    /**
     * @details Create a wine listing by the managed portfolio
     * @remarks *POST /v1/offer/portfolio/{portfolioID}*
     * @param {string} portfolioID The portfolioID of the portfolio getting available listings for
     * id: string,
     * listedAt: string,
     * lwin18": string",
     * offerPrice: {
     *    amount: 0,
     *    currency: "string"
     * }
     * */
    createOfferListing: ReturnType<typeof createOfferListing>;
    /**
     * @details Update a wine listing that has been created by the managed portfolio
     * @remarks *PUT /v1/offer/portfolio/{portfolioID}*
     * @param {string} portfolioID The portfolioID of the portfolio getting available listings for
     * id: string,
     * listedAt: string,
     * lwin18": string",
     * offerPrice: {
     *    amount: 0,
     *    currency: "string"
     * }
     * */
    putOfferListing: ReturnType<typeof putOfferListing>;
    /**
     * @details Update a wine listing that has been created by the managed portfolio
     * @remarks *DELETE /v1/offer/portfolio/{portfolioID}/trade/{tradeId}*
     * @param {string} portfolioID The portfolioID of the portfolio getting available listings for
     * @param {string} tradeId The tradeId of the portfolio listing
     * id: string,
     * listedAt: string,
     * lwin18": string",
     * offerPrice: {
     *    amount: 0,
     *    currency: "string"
     * }
     * */
    cancelOfferListing: ReturnType<typeof cancelOfferListing>;
    /**
     *@details Creates and returns a link to display Stripe's subscription management UI
     * @param {string} returnUrl
     * @remarks *POST /v1/payment/{userID}/portfolio/{portfolioId}/stripe/portal*
     */
    createStripeSubscriptionManagementUrl: ReturnType<typeof createStripeSubscriptionManagementUrl>;
    /**
     * @details Retrieve unique bank wire reference key for specified user
     *
     * @remarks *GET /v1/user/{userId}/key*
     */
    getBankWireKeyReference: ReturnType<typeof getBankWireKeyReference>;
    /**
     * @details Retrieve information for US domestic bank wire
     *
     *  @remarks *GET /v1/user/{userId}/wire*
     */
    getBankWireUsInformation: ReturnType<typeof getBankWireUsInformation>;
    /**
     * @details Request body for httpRequest to get user's information
     * @remarks *GET /v1/user/${userId}/information*
     */
    getUserInformation: ReturnType<typeof getUserInformation>;
    /**
     * @details Retrieve information about a user's investing profile
     * @remarks *GET /v1/user/{userId}/profile*
     */
    getUserProfile: ReturnType<typeof getUserProfile>;
    /**
     * @details Retrieve information about a user's autopay status and data
     * @remarks *GET /v1/user/${userId}/autopay/status*
     */
    putUserAutoPayUpdate: ReturnType<typeof putUserAutoPayUpdate>;
    /**
     * @details Updates information about a user's autopay status
     * @remarks *GET /v1/user/${userId}/autopay/cancel*
     */
    putUserAutoPayCancel: ReturnType<typeof putUserAutoPayCancel>;
    /**
     * @details Updates information about a user's autopay payment source
     * @remarks *GET /v1/user/${userId}/autopay/update*
     */
    getUserAutoPayStatus: ReturnType<typeof getUserAutoPayStatus>;
    /**
     * @details Gets information about a user's withdrawal eligibility
     * @remarks *GET /v1/user/${userId}/withdrawalEligibility*
     */
    getUserWithdrawalEligibility: ReturnType<typeof getUserWithdrawalEligibility>;
    /**
     * @details Gets information about a user's linked banks
     * @remarks *GET /v1/user/${userId}/linkedBanks*
     */
    getUserLinkedBanks: ReturnType<typeof getUserLinkedBanks>;
    /**
     * @details Posts to initiate Modern Treasury email for account linking
     * @remarks *POST /v1/user/${userId}/linkedBanks*
     * @param { string } accountType SwiftIBAN, SwiftAccountNumber or AccountNumberRouting
     */
    postUserLinkedBanks: ReturnType<typeof postUserLinkedBanks>;
    /**
     * @details Posts to initiate withdrawal
     * @remarks *POST /v1/user/${userId}/withdrawal*
     * @param { amount: number, currency: string } amount
     * @param { string } portfolioID
     */
    postUserWithdrawal: ReturnType<typeof postUserWithdrawal>;
    /**
     * @details Retreive a user's referral status
     */
    getUserReferralProfileStatus: ReturnType<typeof getUserReferralProfileStatus>;
    /**
     * @details Send invite emails to the provided emails
     */
    postSendInviteEmails: ReturnType<typeof postSendInviteEmails>;

    /** Updates the user's personal information */
    putUserInformation: ReturnType<typeof putUserInformation>;

    /** Updates the user's password */
    putUserPassword: ReturnType<typeof putUserPassword>;

    /** Updates the user's investment strategy */
    putUpdateInvestmentPlan: ReturnType<typeof putUpdateInvestmentPlan>;

    /** Updates the user's portfolio's allocation classification */
    putAllocationClassification: ReturnType<typeof putAllocationClassification>;

    /** Gets account statements */
    getAccountStatements: ReturnType<typeof getAccountStatements>;

    /** Gets all months that contain an account statement */
    getAccountStatementMonths: ReturnType<typeof getAccountStatementMonths>;

    /** Retreives the download link for the provided document id */
    getAccountDocumentLink: ReturnType<typeof getAccountDocumentLink>;

    /** Posts all wines for liquidation */
    postBulkLiquidateWines: ReturnType<typeof postBulkLiquidateWines>;

    /** Autolist all wines for liquidation */
    postBulkAutolistWines: ReturnType<typeof postBulkAutolistWines>;

    /** Cancels wines for liquidation */
    cancelBulkLiquidateWines: ReturnType<typeof cancelBulkLiquidateWines>;

    /** Puts the provided investment profile on to the uers account and sets their investment strategy. */
    putUserProfile: ReturnType<typeof putUserProfile>;

    /** Create a new impact signup user */
    postImpactSignup: ReturnType<typeof postImpactSignup>;

    /** Create a new impact signup user */
    postImpactBookACall: ReturnType<typeof postImpactBookACall>;

    putLinkReferralInviteCode: ReturnType<typeof putLinkReferralInviteCode>;

    getPortfolioCharges: ReturnType<typeof getPortfolioCharges>;

    /** Gets unique wire transfer information for a users portfolio */
    getUniqueWireInformation: ReturnType<typeof getUniqueWireInformation>;

    /** Gets the user's preferences */
    getUserPreferences: ReturnType<typeof getUserPreferences>;

    /** Updates the user's preferences */
    updateUserPreferences: ReturnType<typeof updateUserPreferences>;

    /** Will get all users portfolios */
    getUserPortfolios: ReturnType<typeof getUserPortfolios>;

    /** Will make a new whiskey portfolio */
    createWhiskeyvestPortfolio: ReturnType<typeof createWhiskeyvestPortfolio>;

    /** Updates dynamic pricing for all listed wines in liquidation
     * @param {boolean} fmvPEG The boolean for active dynamic pricing
     * */
    patchBulkFvmpeg: ReturnType<typeof patchBulkFmvpeg>;

    /** Updates dynamic pricing for selected wine in liquidation
     * @param {string} tradeId The tradeId of the portfolio listing
     * @param {boolean} fmvPEG The boolean for active dynamic pricing
     * */
    patchFmvpeg: ReturnType<typeof patchFmvpeg>;
    /** Will get an offer group for managed upsells */
    getUpsellOfferGroup: ReturnType<typeof getUpsellOfferGroup>;

    /** reserve offers in an upsell group */
    reserveUpsellOfferGroup: ReturnType<typeof reserveUpsellOfferGroup>;

    /**
     * Get reservations in a group
     */
    getReservationsInGroup: ReturnType<typeof getReservationsInGroup>;

    /** cancel offer reservations */
    cancelReservedOffers: ReturnType<typeof cancelReservedOffers>;

    /** purchase reserved offers */
    purchaseReservedOffers: ReturnType<typeof purchaseReservedOffers>;

    /** start an escrow for reserved offers */
    startReservedEscrow: ReturnType<typeof startReservedEscrow>;
}

export const makeManagedApis = (managedConfig: Exclude<ApiGenerationOptions, 'getTradingPortfolioId'>): ManagedApis => {
    const config = managedInititializationConfig(managedConfig);
    return {
        putLinkCashAwardReferral: putLinkCashAwardReferral(config),
        getUserCashAwardReferralStatus: getUserCashAwardReferralStatus(config),
        createNewUser: createNewUser(config),
        createAchAccount: createAchAccount(config),
        createStripeSecretKey: createStripeSecretKey(config),
        createStripePaymentIntent: createStripePaymentIntent(config),
        createPaymentSubscription: createPaymentSubscription(config),
        createPlaidPaymentIntent: createPlaidPaymentIntent(config),
        createBitpayInvoice: createBitpayInvoice(config),
        getPlaidLinkToken: getPlaidLinkToken(config),
        getUserHasSubscriptions: getUserHasSubscriptions(config),
        getUserPaymentMethods: getUserPaymentMethods(config),
        getActiveBids: getActiveBids(config),
        getPortfolioFees: getPortfolioFees(config),
        getPortfolioAssetsAsCsv: getPortfolioAssetsAsCsv(config),
        getPortfolioWine: getPortfolioWine(config),
        getUserPortfolio: getUserPortfolio(config),
        getPendingOfferListings: getPendingOfferListings(config),
        createOfferListing: createOfferListing(config),
        putOfferListing: putOfferListing(config),
        cancelOfferListing: cancelOfferListing(config),
        getOfferListings: getOfferListings(config),
        getUserWines: getUserWines(config),
        createStripeSubscriptionManagementUrl: createStripeSubscriptionManagementUrl(config),
        getBankWireKeyReference: getBankWireKeyReference(config),
        getBankWireUsInformation: getBankWireUsInformation(config),
        getUserInformation: getUserInformation(config),
        getUserAutoPayStatus: getUserAutoPayStatus(config),
        putUserAutoPayUpdate: putUserAutoPayUpdate(config),
        putUserAutoPayCancel: putUserAutoPayCancel(config),
        getUserWithdrawalEligibility: getUserWithdrawalEligibility(config),
        getUserLinkedBanks: getUserLinkedBanks(config),
        postUserLinkedBanks: postUserLinkedBanks(config),
        postUserWithdrawal: postUserWithdrawal(config),
        getUserProfile: getUserProfile(config),
        getUserReferralProfileStatus: getUserReferralProfileStatus(config),
        postSendInviteEmails: postSendInviteEmails(config),
        putUserInformation: putUserInformation(config),
        putUserPassword: putUserPassword(config),
        putUpdateInvestmentPlan: putUpdateInvestmentPlan(config),
        putAllocationClassification: putAllocationClassification(config),
        getAccountStatementMonths: getAccountStatementMonths(config),
        getAccountStatements: getAccountStatements(config),
        getAccountDocumentLink: getAccountDocumentLink(config),
        postBulkLiquidateWines: postBulkLiquidateWines(config),
        cancelBulkLiquidateWines: cancelBulkLiquidateWines(config),
        putUserProfile: putUserProfile(config),
        postImpactSignup: postImpactSignup(config),
        postImpactBookACall: postImpactBookACall(config),
        putLinkReferralInviteCode: putLinkReferralInviteCode(config),
        getPortfolioCharges: getPortfolioCharges(config),
        getUniqueWireInformation: getUniqueWireInformation(config),
        getUserPreferences: getUserPreferences(config),
        updateUserPreferences: updateUserPreferences(config),
        createWhiskeyvestPortfolio: createWhiskeyvestPortfolio(config),
        getUserPortfolios: getUserPortfolios(config),
        postBulkAutolistWines: postBulkAutolistWines(config),
        patchBulkFvmpeg: patchBulkFmvpeg(config),
        patchFmvpeg: patchFmvpeg(config),
        getUpsellOfferGroup: getUpsellOfferGroup(config),
        reserveUpsellOfferGroup: reserveUpsellOfferGroup(config),
        getReservationsInGroup: getReservationsInGroup(config),
        cancelReservedOffers: cancelReservedOffers(config),
        purchaseReservedOffers: purchaseReservedOffers(config),
        startReservedEscrow: startReservedEscrow(config)
    };
};
