import {
    makeManagedApis,
    makeStrapiApis,
    makeTradingApis,
    ManagedApis,
    StrapiApis,
    TradingApis
} from './api-generation';
import { getHostPathGenerator } from './getHostPathGenerator';
import type { ClientsideAuthentication } from '~/stores/ClientsideAuthentication';

export * from '~/networking/models';

export enum ApiTypes {
    CMS = 'cms',
    Strapi = 'strapi',
    Managed = 'managed',
    Trading = 'trading'
}
export type ApiGenerationOptions = UseVinovestNetworkingOptions & {
    getPortfolioId: () => string;
    getUserId: () => string;
    getAccessToken: () => string;
    getTradingPortfolioId: () => string;
    getCurrencyCode: () => string;
};

export interface MakeRequestsOptions {
    getHeaders: (
        secure?: boolean,
        extraHeaders?: { [key: string]: string } | Headers
    ) => { [key: string]: string } | Headers;
    getRequestUrl: (pathname: string) => string;
}

export interface MakeTradingRequestOptions extends MakeRequestsOptions {
    getUserId: () => string;
    getTradingPortfolioId: () => string;
}

export interface MakeMangedRequestOptions extends MakeRequestsOptions {
    getPortfolioId: () => string;
    getUserId: () => string;
}

export type StrapiRequestOptions = Pick<MakeRequestsOptions, 'getHeaders'> & Pick<MakeRequestsOptions, 'getRequestUrl'>;

export interface UseVinovestNetworkingOptions {
    getPortfolioId?: () => string;
    getTradingPortfolioId?: () => string;
    getUserId?: () => string;
    getCurrencyCode?: () => string;
}

export class VinovestNetworkingClient {
    constructor({ getPortfolioId, getUserId, getTradingPortfolioId, getCurrencyCode }: UseVinovestNetworkingOptions) {
        this.getUserId = getUserId || (() => '');
        this.getTradingPortfolioId = getTradingPortfolioId || (() => '');
        this.getPortfolioId = getPortfolioId || (() => '');
        this.getAccessToken = () => {
            // another workaround until this class can be removed entirely

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            const auth: ClientsideAuthentication | undefined = window?.Vino?.auth;
            return auth?.jwt as string;
        };
        this.getCurrencyCode = getCurrencyCode || (() => '');
    }

    private get tradingConfig() {
        return {
            ...this.config,
            getRequestUrl: getHostPathGenerator({
                type: ApiTypes.Trading
            })
        };
    }

    private get config() {
        return {
            strapiToken: process.env.NEXT_PUBLIC_STRAPI_PUBLIC_ACCESS_TOKEN,
            getUserId: this.getUserId,
            getAccessToken: this.getAccessToken,
            getPortfolioId: this.getPortfolioId,
            getTradingPortfolioId: this.getTradingPortfolioId,
            getCurrencyCode: this.getCurrencyCode
        };
    }

    get managed(): ManagedApis {
        if (!this.managedInstance) {
            this.managedInstance = makeManagedApis(this.config);
        }
        return this.managedInstance;
    }

    get trading(): TradingApis {
        if (!this.tradingInstance) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.tradingInstance = makeTradingApis(this.tradingConfig);
        }
        return this.tradingInstance;
    }

    get strapi(): StrapiApis {
        if (!this.strapiInstance) {
            this.strapiInstance = makeStrapiApis(this.config);
        }
        return this.strapiInstance;
    }

    public getPortfolioId: () => string;

    public getAccessToken: () => string;

    public getUserId: () => string;

    public getTradingPortfolioId: () => string;

    public getCurrencyCode: () => string;

    private managedInstance?: ManagedApis;

    private tradingInstance?: TradingApis;

    private strapiInstance?: StrapiApis;
}
