/**
 * All interface keys are mapped to contain a preceding underscore
 * in the httpRequest itself. This is the external interface.
 * The default filtering parameters are poor for strapi see below:
 * {@link https://api.staging.vinovest.co/swagger}
 */
export const strapiQueryMapping = {
    limit: '_limit',
    sort: '_sort',
    start: '_start',
    constains: '_contains',
    containsCaseSensitive: '_containss',
    getRecordsInArray: '_in',
    getRecordsNotInArray: '_nin',
    notEqual: '_ne',
    lessThan: '_lt',
    lessThanOrEqual: '_lte',
    greaterThan: '_gt',
    greaterThanOrEqual: '_gte'
};

export interface StrapiParameters {
    // Maximum number of results possible
    limit: number;
    // Sort according to a specific field.
    sort: number;
    // Skip a specific number of entries (especially useful for pagination)
    start: number;
    // Get records that contains a value
    contains: string;
    // Get records that contains (case sensitive) a value
    containsCaseSensitive: string;
    // Get records that matches any value in the array of values
    getRecordsInArray: string;
    // Get records that doesn't match any value in the array of values
    getRecordsNotInArray: string;
    // Get records that are not equals to something
    notEqual: string;
    // Get record that are lower than a value
    lessThan: string;
    // Get records that are lower than or equal to a value
    lessThanOrEqual: string;
    // Get records that are greater than a value
    greaterThan: string;
    // Get records that are greater than or equal a value
    greaterThanOrEqual: string;
}
