import { getHostPathGenerator } from '~/networking/client/getHostPathGenerator';

import { headerFactory } from '../../headerFactory';
import { ApiTypes } from '../../VinovestNetworkingClient';

export const strapiIntializationConfiguration = ({ strapiToken }: { strapiToken: string }) => ({
    getRequestUrl: getHostPathGenerator({ type: ApiTypes.Strapi }),
    getHeaders: (secure?: boolean, extraHeaders?: { [key: string]: string } | Headers) =>
        headerFactory({ secure, strapiToken, type: ApiTypes.Strapi, headers: extraHeaders })
});
