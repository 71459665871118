import { WineDescriptionResponse } from '~/networking/api/strapi/responses';
import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getLwin7Descriptions =
    ({ getHeaders, getRequestUrl }: StrapiRequestOptions) =>
    async (lwin7Ids: string[] | string): Promise<Response<WineDescriptionResponse>> => {
        const queryParameters: string = Array.isArray(lwin7Ids)
            ? lwin7Ids.map((lwin7) => `lwin7=${lwin7}`).join('&')
            : lwin7Ids;
        const headers = await getHeaders(true);
        const getLwin7DescriptionsEndpoint = getRequestUrl(`/lwin-7-s?${queryParameters}`);

        return httpRequest.get(getLwin7DescriptionsEndpoint, { headers, keepalive: true });
    };
