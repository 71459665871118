import { WineVintagesResponse } from '~/networking/api/strapi/responses';
import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getLwin11CriticsReviews =
    ({ getHeaders, getRequestUrl }: StrapiRequestOptions) =>
    async (lwin11Ids: string[] | string): Promise<Response<WineVintagesResponse>> => {
        const queryParameters: string = Array.isArray(lwin11Ids)
            ? lwin11Ids.map((lwin11) => `lwin11=${lwin11}`).join('&')
            : `lwin11=${lwin11Ids}`;
        const headers = getHeaders(true);
        const getLwin11CriticsReviewsEndpoint = getRequestUrl(`/critics?${queryParameters}`);

        return httpRequest.get(getLwin11CriticsReviewsEndpoint, { headers, keepalive: true });
    };
