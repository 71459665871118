import { CriticInformation } from './StrapiWineCritics';

/** Response of httpRequest to Strapi to retrieve vintage metadata of a set of wines.  */
export type WineVintagesResponse = WineVintage[];

/**
 * Specific vintage information for a wine corresponding to the LWIN 11 number including
 * critic scores, bottle description, and images of the bottle.
 */
export interface WineVintage {
    id: string;
    lwin11: string;
    vintage: number;
    bottleImage: ImageMetadata;
    background: ImageMetadata;
    critics: CriticInformation[];
    badges: Badge[];
}

export interface Badge {
    id: string;
    name: string;
    image: {
        id: string;
        name: string;
        alternativeText: string;
        caption: string;
        width: number;
        height: number;
        hash: string;
        ext: string;
        mime: string;
        size: number;
        url: string;
        previewUrl: string;
        provider: string;
    };
    description: string;
    vintages: string[];
    statistic: number;
    statisticDescription: string;
    published_at: string;
    created_by: string;
    updated_by: string;
}

/** Wine Bottle image including its URL and name */
export interface ImageMetadata {
    url: string;
    name: string;
    mime: string;
    formats: {
        large: {
            url: string;
        };
        medium: {
            url: string;
        };
        small: {
            url: string;
        };
        thumbnail: {
            url: string;
        };
    };
}
[
    {
        id: 'string',
        name: 'string',
        description: 'string',
        background: {
            id: 'string',
            name: 'string',
            alternativeText: 'string',
            caption: 'string',
            width: 0,
            height: 0,
            formats: {},
            hash: 'string',
            ext: 'string',
            mime: 'string',
            size: 0,
            url: 'string',
            previewUrl: 'string',
            provider: 'string',
            provider_metadata: {},
            related: 'string',
            created_by: 'string',
            updated_by: 'string'
        },
        lwin7s: [
            {
                id: 'string',
                displayName: 'string',
                lwin7: 'string',
                color: 'string',
                type: 'string',
                subType: 'string',
                region: 'string',
                subRegion: 'string',
                country: 'string',
                description: 'string',
                slug: 'string',
                countrySlug: 'string',
                regionSlug: 'string',
                vintages: ['string'],
                localizations: ['string'],
                locale: 'string',
                published_at: 'string',
                created_by: 'string',
                updated_by: 'string'
            }
        ],
        published_at: '2021-12-27T01:10:06.470Z'
    }
];
