import { useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { SentryHelper } from '~/helpers/Sentry/SentryHelper';
import { VinovestNetworkingHelper } from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';
import { GetUserPaymentMethodsResponse } from '~/networking';

import { useManagedPortfolioQuery } from '../queries/portfolios/managed/useManagedPortfolioQuery';

/** Returns user's saved bank and credit card accounts. */
export const useSavedPaymentMethod = (destinationPortfolioId: string | undefined) => {
    const { isAuthenticated } = useAuthentication();
    const { data: managedPortfolio } = useManagedPortfolioQuery();
    const portfolioId = managedPortfolio?.id ? managedPortfolio?.id : destinationPortfolioId;

    return useQuery(
        [queries.getSavedPaymentMethods, destinationPortfolioId],
        async (): Promise<GetUserPaymentMethodsResponse | undefined> => {
            try {
                const { managed } = await VinovestNetworkingHelper.getClient();
                const result = await managed.getUserPaymentMethods(portfolioId || '');
                if (result.ok) {
                    return result.data;
                }
                return undefined;
            } catch (error) {
                SentryHelper.captureException(error, {
                    extra: { isAuthenticated }
                });
            }
            return undefined;
        },
        {
            enabled: isAuthenticated && !!portfolioId
        }
    );
};
