/**
 * This file is autogenerated, do not modify. It will be created and
 * overwritten again every build
 */

export * from './AccountDocument';

export * from './ActiveBid';

export * from './AutoInvestSettings';

export * from './BankAccountDetails';

export * from './CreditCardDetails';

export * from './CurrencyAmount';

export * from './DepositFrequencies';

export * from './DeviceSettings';

export * from './FeeTypes';

export * from './LoadingStatus';

export * from './NewUserQuiz';

export * from './PaginatedResponse';

export * from './PendingTransfer';

export * from './PortfolioCharges';

export * from './PortfolioFee';

export * from './PortfolioFees';

export * from './PortfolioPerformanceRanges';

export * from './PortfolioPricingData';

export * from './StrapiParameters';

export * from './User';

export * from './UserPortfolioOverview';

export * from './ValueOf';

export * from './WineDetails';

export * from './WineLwinIds';

export * from './WineProfile';
