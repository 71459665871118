/**
 * Generates a parameter string from key/value pairs.
 * @param httpRequestParams - key/value pairs of parameters to add to request.
 * @param httpRequestParameterMapping - key/value pair mapping so that more descriptive parameter names can be used in place of poor ones.
 * - {@link https://api.staging.vinovest.co/swagger}
 */
export const buildParameters = (
    httpRequestParams: Record<string | number, string | number | boolean | undefined>,
    httpRequestParameterMapping?: { [key: string]: string }
) => {
    return Object.entries(httpRequestParams).reduce((queryParams, [key, value], i, arr) => {
        if (value) {
            if (i === 0) {
                queryParams += '?';
            }

            queryParams += `${httpRequestParameterMapping ? httpRequestParameterMapping[key] : key}=${value}`;
            if (i !== arr.length - 1) {
                queryParams += '&';
            }
        }

        return queryParams;
    }, '');
};
