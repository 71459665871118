import { WineDescriptionResponse } from '~/networking/api/strapi/responses';
import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getLwin7sFromRegion =
    ({ getHeaders, getRequestUrl }: StrapiRequestOptions) =>
    async (region: string): Promise<Response<WineDescriptionResponse>> => {
        const headers = getHeaders(true);
        const getLwin7sFromRegionEndpoint = getRequestUrl(`/lwin-7-s?region=${region}`);

        return httpRequest.get(getLwin7sFromRegionEndpoint, { headers, keepalive: true });
    };
