import { StrapiWineProducersResponse } from '~/networking/api/strapi/responses/StrapiWineProducers';
import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getProducerById =
    ({
        getHeaders,
        getRequestUrl
    }: StrapiRequestOptions): ((producerId: string) => Promise<Response<StrapiWineProducersResponse>>) =>
    async (producerId: string): Promise<Response<StrapiWineProducersResponse>> => {
        const headers = getHeaders(true);
        const httpRequestUrl = getRequestUrl(`/producers/${producerId}`);

        return httpRequest.get(httpRequestUrl, { headers, keepalive: true });
    };
