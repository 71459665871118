import { WineVintagesResponse } from '~/networking/api/strapi/responses';
import { MakeRequestsOptions } from '~/networking/client/VinovestNetworkingClient';
import { buildParameters } from '~/networking/utils/buildParameters';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getActiveVintages =
    ({ getHeaders, getRequestUrl }: MakeRequestsOptions) =>
    ({
        start,
        limit,
        active = true
    }: {
        start: number;
        limit?: number;
        active?: boolean;
    }): Promise<Response<WineVintagesResponse>> => {
        const headers = getHeaders(true);
        const params = { start, limit, active };
        const httpRequestUrl = getRequestUrl(`/vintages${buildParameters({ ...params })})`);

        return httpRequest.get(httpRequestUrl, { headers });
    };
