import { useContext } from 'react';
import { useQuery } from 'react-query';

import { queries } from '~/constants/queryKeys';
import { TradingPortfolioContext } from '~/context/TradingPortfolioContext';
import { parseLWin16 } from '~/helpers/LwinUtility';
import { getTotalPortfolioValues } from '~/helpers/trading-portfolio/getTotalPortfolioValue';
import { VinovestNetworkingHelper } from '~/helpers/VinovestNetworkingHelper';
import { useAuthentication } from '~/hooks/useAuthentication';

/** Query for fetching users Trading Portfolio */
export const useTradingPortfolioQuery = () => {
    const { isAuthenticated } = useAuthentication();
    const { id } = useContext(TradingPortfolioContext);

    return useQuery(
        [queries.getTradingPortfolio, id],
        async () => {
            const client = await VinovestNetworkingHelper.getClient();

            const tradingInfoResults = await Promise.all([
                client.trading.getTradingPortfolio(),
                client.trading.getTradingPortfolioBottles()
            ]);

            const tradingPortfolioResponse = tradingInfoResults[0];
            const tradingBottlesResponse = tradingInfoResults[1];

            if (tradingPortfolioResponse && tradingBottlesResponse && tradingBottlesResponse.data) {
                const tradingBottles = tradingBottlesResponse.data.bottles ?? [];
                const tradingPortfolioDetails = tradingPortfolioResponse.data;

                const totals = getTotalPortfolioValues(tradingBottles);

                const needsOnboarding =
                    !totals?.costBasis &&
                    !totals?.bottleValue &&
                    !totals?.bottleQuantity &&
                    !tradingPortfolioDetails?.cashBalance?.amount;

                const totalTradingPortfolioValue =
                    (tradingPortfolioDetails?.openBuyOrderValue?.amount ?? 0) +
                    (tradingPortfolioDetails?.totalMarketValue?.amount ?? 0) +
                    (tradingPortfolioDetails?.cashBalance?.amount ?? 0);

                return {
                    ...tradingPortfolioDetails,
                    bottles: tradingBottles?.map((bottle) => ({
                        ...bottle,
                        lwinUtil: parseLWin16(bottle.lwin16)
                    })),
                    totalCashValue: tradingPortfolioDetails?.cashBalance?.amount ?? 0,
                    needsOnboarding,
                    bottleValue: totals?.bottleValue ?? 0,
                    bottleQuantity: totals?.bottleQuantity ?? 0,
                    costBasis: totals?.costBasis ?? 0,
                    totalTradingPortfolioValue,
                    totalMarketValue: tradingPortfolioDetails?.totalMarketValue?.amount ?? 0
                };
            }
            return undefined;
        },
        {
            enabled: Boolean(isAuthenticated && id)
        }
    );
};
