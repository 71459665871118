import { WineVintagesResponse } from '~/networking/api/strapi/responses';
import { StrapiRequestOptions } from '~/networking/client/VinovestNetworkingClient';
import { httpRequest, Response } from '~/networking/utils/httpRequest';

export const getLwin11Vintage =
    ({ getHeaders, getRequestUrl }: StrapiRequestOptions) =>
    async (lwin11Ids: string[]): Promise<Response<WineVintagesResponse>> => {
        const queryParameters = lwin11Ids.map((lwin11) => `lwin11=${lwin11}`).join('&');
        const headers = getHeaders(true);
        const getLwin11VintageEndpoint = getRequestUrl(`/vintages?${queryParameters}`);

        return httpRequest.get(getLwin11VintageEndpoint, { headers, keepalive: true });
    };
